import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { saveAs } from 'file-saver';
import ReactApexChart from 'react-apexcharts';
// material
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Button,
  Select,
  MenuItem,
  Stack,
  CircularProgress
} from '@material-ui/core';
//
import { useTheme } from '@material-ui/core/styles';
import { styled } from '@mui/styles';

import apiHandler from '../../../api/axios-api';

// ----------------------------------------------------------------------

const StyledSelect = styled(Select)(({ theme }) => ({
  // fontWeight: 'bold',
  fontSize: '14px',
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  // fontWeight: 'bold',
  fontSize: '14px',
}));


DeviceNutrientHistorical.propTypes = {
  deviceId: PropTypes.string.isRequired,
  showEvent: PropTypes.bool
};

export default function DeviceNutrientHistorical({ deviceId, showEvent = true }) {
  const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
  const theme = useTheme();
  const [chartPeriod, setChartPeriod] = useState(1);
  const [chartHistoricalSeries, setChartHistoricalSeries] = useState([]);
  const [chartEventsSeries, setChartEventsSeries] = useState([]);
  const [timezone, setTimezone] = useState(timezoneName);
  const [updating, setUpdating] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const downloadData = (period = 0) => {
    setDownloading(true);
    apiHandler(`devices/${deviceId}/historical/export?p=${period}&tz=${timezone}`, { responseType: 'blob' }).then(
      (res) => {
        saveAs(res.data, `historical-${deviceId}.csv`);
        setDownloading(false);
      }
    );
  };

  const fetchHistorical = useCallback((period = 0) => {
    setUpdating(true);
    apiHandler(`devices/${deviceId}/historical?p=${period}&tz=${timezone}`).then((res) => {
      const chartHistoricalSeriesData = [];
      const ecSeries = [];
      const phSeries = [];
      const temperatureSerie = [];

      try {
        res.data.time.map((t, i) => {
          const unixTime = Date.parse(t) - timezoneOffset;
          ecSeries.push([unixTime, res.data.ec[i]]);
          phSeries.push([unixTime, res.data.ph[i]]);
          temperatureSerie.push([unixTime, res.data.temperature[i]]);
          return null;
        });
      } catch {
        // do nothing
      }

      if (ecSeries !== []) {
        chartHistoricalSeriesData.push({ name: 'EC', data: ecSeries });
      }
      if (phSeries !== []) {
        chartHistoricalSeriesData.push({ name: 'pH', data: phSeries });
      }
      if (temperatureSerie !== []) {
        chartHistoricalSeriesData.push({ name: 'Temperature', data: temperatureSerie });
      }
      setChartHistoricalSeries(chartHistoricalSeriesData);
      setUpdating(false);
    });
  },
    [deviceId]
  );

  const fetchEvents = useCallback(
    (period = 0) => {
      apiHandler(`devices/${deviceId}/events?p=${period}`).then((res) => {
        const chartEventsSeriesData = [];
        const aSeries = [];
        const bSeries = [];
        const phSeries = [];

        try {
          res.data.time.map((t, i) => {
            const unixTime = Date.parse(t) + 25200000;
            aSeries.push([unixTime, res.data.a[i]]);
            bSeries.push([unixTime, res.data.b[i]]);
            phSeries.push([unixTime, res.data.ph[i]]);
            return null;
          });
        } catch {
          // do nothing
        }

        if (aSeries !== []) {
          chartEventsSeriesData.push({ name: 'A', data: aSeries });
        }
        if (phSeries !== []) {
          chartEventsSeriesData.push({ name: 'B', data: bSeries });
        }
        if (bSeries !== []) {
          chartEventsSeriesData.push({ name: 'PH', data: phSeries });
        }
        setChartEventsSeries(chartEventsSeriesData);
      });
    },
    [deviceId]
  );
  useEffect(() => {
    fetchHistorical(chartPeriod);
    if (showEvent) {
      fetchEvents(chartPeriod);
    }
  }, [fetchHistorical, fetchEvents, chartPeriod, showEvent]);

  const historicalOptions = {
    colors: [theme.palette.primary.main, theme.palette.warning.main, theme.palette.info.main],
    chart: {
      toolbar: {
        show: false
      }
    },
    stroke: {
      width: 3
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: [
      {
        title: {
          text: 'EC (μS/cm)'
        },
        seriesName: 'EC'
      },
      {
        title: {
          text: 'pH (Unit)'
        },
        seriesName: 'pH'
      },
      {
        title: {
          text: 'Temperature (°C)'
        },
        opposite: true,
        seriesName: 'Temperature'
      }
    ],
    tooltip: {
      x: {
        format: 'dd/MMM/yyyy hh:mm:ss'
      }
    }
  };

  const eventsOptions = {
    colors: [theme.palette.success.main, theme.palette.error.main, theme.palette.grey[600]],
    chart: {
      toolbar: {
        show: false
      },
      animations: {
        enabled: false
      }
      // stacked: true,
    },
    // plotOptions: { bar: { columnWidth: '100%'} },
    // stroke: {
    //   curve: 'stepline',
    // },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      title: {
        text: 'Nutrient Used (Liters)'
      }
    },
    tooltip: {
      x: {
        format: 'dd/MMM/yyyy hh:mm:ss'
      }
    }
  };

  return (
    <Card>
      <CardHeader title="Historical" subheader="Nutrient and Temperature" />
      <CardContent>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <ToggleButtonGroup
            value={chartPeriod}
            exclusive

            onChange={(event, period) => {
              setChartPeriod(period);
              fetchHistorical(period);
              if (showEvent) {
                fetchEvents(period);
              }
            }}
            aria-label="text alignment"
          >
            <ToggleButton disabled={updating} value={1} aria-label="24 Hours" size="small">
              &nbsp;&nbsp;&nbsp;1 Day&nbsp;&nbsp;&nbsp;
            </ToggleButton>
            <ToggleButton disabled={updating} value={2} aria-label="2 Days" size="small">
              &nbsp;&nbsp;&nbsp;2 Days&nbsp;&nbsp;&nbsp;
            </ToggleButton>
            <ToggleButton disabled={updating} value={3} aria-label="7 Days" size="small">
              &nbsp;&nbsp;&nbsp;7 Days&nbsp;&nbsp;&nbsp;
            </ToggleButton>
            <ToggleButton disabled={updating} value={4} aria-label="30 Days" size="small">
              &nbsp;&nbsp;&nbsp;30 Days&nbsp;&nbsp;&nbsp;
            </ToggleButton>
            <ToggleButton disabled={updating} value={5} aria-label="3 Months" size="small">
              &nbsp;&nbsp;&nbsp;3 Months&nbsp;&nbsp;&nbsp;
            </ToggleButton>
            <ToggleButton disabled={updating} value={6} aria-label="6 Months" size="small">
              &nbsp;&nbsp;&nbsp;6 Months&nbsp;&nbsp;&nbsp;
            </ToggleButton>
          </ToggleButtonGroup>
          <Stack direction="row" alignItems="center">
            <StyledSelect
              size="small"
              value={timezone}
              disabled={downloading}
              onChange={(event, tz) => {
                setTimezone(event.target.value);
                // TODO: save timezone to cookies
              }}
              aria-label="text alignment"
            >
              <StyledMenuItem value={timezoneName} size="small">{timezoneName}</StyledMenuItem>
              <StyledMenuItem value={'Etc/UTC'} size="small">UTC</StyledMenuItem>
            </StyledSelect>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="contained"
              disabled={downloading}
              onClick={() => {
                downloadData(chartPeriod);
              }}
            >
              {downloading ? 'Generating Report..' : 'Download CSV Report'}&nbsp;&nbsp;&nbsp;
              {downloading && <CircularProgress color="grey" size={20} />}
            </Button>
          </Stack>
        </Stack>
      </CardContent>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={chartHistoricalSeries}
          options={historicalOptions}
          height={400}
        />
      </Box>
      {showEvent && (
        <Box sx={{ p: 3, pb: 1 }} dir="ltr">
          <ReactApexChart
            type="bar"
            series={chartEventsSeries}
            options={eventsOptions}
            height={200}
          />
        </Box>
      )}
    </Card>
  );
}

import PropTypes from 'prop-types';
// import React, { useState, useEffect } from 'react';

// material
import { Grid } from '@material-ui/core';
// components
import { DevicePowerStatus } from '../_dashboard/devices';

Switch.propTypes = {
  // deviceId: PropTypes.string,
  data: PropTypes.any.isRequired
};

export default function Switch({ data }) {
  return (
    <>
      {data.channel0 && (
        <Grid item xs={12} sm={4} md={3}>
          <DevicePowerStatus
            measureName="Channel 1"
            subValue=""
            value={data.channel0.power ? 'ON' : 'OFF'}
          />
        </Grid>
      )}
      {data.channel1 && (
        <Grid item xs={12} sm={4} md={3}>
          <DevicePowerStatus
            measureName="Channel 2"
            subValue=""
            value={data.channel1.power ? 'ON' : 'OFF'}
          />
        </Grid>
      )}
      {data.channel2 && (
        <Grid item xs={12} sm={4} md={3}>
          <DevicePowerStatus
            measureName="Channel 3"
            subValue=""
            value={data.channel2.power ? 'ON' : 'OFF'}
          />
        </Grid>
      )}
      {data.channel3 && (
        <Grid item xs={12} sm={4} md={3}>
          <DevicePowerStatus
            measureName="Channel 4"
            subValue=""
            value={data.channel3.power ? 'ON' : 'OFF'}
          />
        </Grid>
      )}
    </>
  );
}

import PropTypes from 'prop-types';
// material
import { styled } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter
}));

// ----------------------------------------------------------------------
DeviceNutrientStatus.propTypes = {
  value: PropTypes.number,
  unitName: PropTypes.string,
  measureName: PropTypes.string,
  valueFixed: PropTypes.number,
}

export default function DeviceNutrientStatus({ value, unitName, measureName, valueFixed=0 }) {
  return (
    <RootStyle>
      {value ?
        <Typography variant="h3">{value.toFixed(valueFixed)} {unitName}</Typography>
        :
        <Typography variant="h3">N/A</Typography>
      }
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {measureName}
      </Typography>
    </RootStyle>
  );
}

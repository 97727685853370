import PropTypes from 'prop-types';
// material
import { styled } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';

// ----------------------------------------------------------------------

const OnStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.success.darker,
  backgroundColor: theme.palette.success.lighter
}));

const OffStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter
}));

const InfoStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter
}));

// ----------------------------------------------------------------------
DevicePowerStatus.propTypes = {
  value: PropTypes.any,
  subValue: PropTypes.string,
  measureName: PropTypes.string,
}

export default function DevicePowerStatus({ value, subValue, measureName }) {
  let res;
  if (value === 'ON') {
    res = (
      <OnStyle>
        {value ?
          <Typography variant="h3">{value}</Typography>
          :
          <Typography variant="h3">N/A</Typography>
        }
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {subValue}
        </Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {measureName}
        </Typography>
      </OnStyle>
    );
  }
  else if (value === 'OFF') {
    subValue = '-';
    res = (
      <OffStyle>
        {value ?
          <Typography variant="h3">{value}</Typography>
          :
          <Typography variant="h3">N/A</Typography>
        }
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {measureName}
        </Typography>
      </OffStyle>
    );
  }
  else {
    res = (
      <InfoStyle>
        {value ?
          <Typography variant="h3">{value}</Typography>
          :
          <Typography variant="h3">N/A</Typography>
        }
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {measureName}
        </Typography>
      </InfoStyle>
    );
  }
  return res;
}

import React, { useEffect } from "react";
import { makeStyles } from '@mui/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide
} from '@mui/material';

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    bottom: 16,
  }
});

const Transition = React.forwardRef((props, ref) => (
 <Slide direction="up" ref={ref} {...props} />
));

export default function ModalPdpa() {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  useEffect(() => {
    const isAcceptCookiePolicy = getCookie('accept_cookie_policy');

    if (isAcceptCookiePolicy !== "true") {
      handleOpen();
    }
  }, []);

  const getCookie = (name) => {
    const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`);
    const arr = document.cookie.match(reg);

    if (arr) {
      return arr[2];
    }

    return null;
  }

  const acceptCookiePolicy = () => {
    const date = new Date();
    const year = 10;
    date.setFullYear(date.getFullYear() + year);
    document.cookie = `accept_cookie_policy=true;expires=${date};path=/`;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleAcceptCookie = () => {
    acceptCookiePolicy();
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        classes={{
          paper: classes.dialog
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Accept cookie policy?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This site uses cookies to manage personal data and improve user experience.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Decline</Button>
          <Button onClick={handleAcceptCookie} autoFocus>Accept</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

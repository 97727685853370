import PropTypes from 'prop-types';
// material
// import { styled } from '@material-ui/core/styles';
// import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import { styled, Card, Typography } from '@mui/material';
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  blinkBgAlert: {
    animation: `$blinkingBg 1s infinite`
  },
  "@keyframes blinkingBg": {
    "0%": {
      color: '#fff',
      backgroundColor: '#FF4842',
    },
    "49%": {
      color: '#7A0C2E',
      backgroundColor: '#FFE7D9',
    },
    "50%": {
      color: '#7A0C2E',
      backgroundColor: '#FFE7D9',
    },
    // "99%": {
    //   color: '#7A0C2E',
    //   backgroundColor: '#FFE7D9',
    // },
    "100%": {
      color: '#fff',
      backgroundColor: '#FF4842',
    },
  },
}));

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.error.darker,
  backgroundColor: theme.palette.error.lighter
})
);

// ----------------------------------------------------------------------
DeviceAmbientStatus.propTypes = {
  value: PropTypes.number,
  unitName: PropTypes.string,
  measureName: PropTypes.string,
  valueFixed: PropTypes.number,
  isAlert: PropTypes.bool,
}

export default function DeviceAmbientStatus({ value, unitName, measureName, valueFixed = 0, isAlert = false }) {
  const classes = useStyles();
  const blinkClass = isAlert ? classes.blinkBgAlert : null;

  return (
    <RootStyle className={blinkClass}>
      {value ?
        <Typography variant="h3">{value.toFixed(valueFixed)} {unitName}</Typography>
        :
        <Typography variant="h3">N/A</Typography>
      }
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {measureName}
      </Typography>
    </RootStyle>
  );
}

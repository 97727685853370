export default function productTypeToName(productType, productVariant) {
  switch (productType) {
    case 'TH_DOSER':
      return 'Nutrient Doser';
    case 'TH_SWITCH':
      return productVariant === '4CH'
        ? 'General Purpose Switch (4 Channels)'
        : 'General Purpose Switch';
    case 'TH_DIMMER':
      return productVariant === '4CH'
        ? 'Growlight Controller (4 Channels)'
        : 'Growlight Controller';
    case 'TH_DIMMER_TASMOTA':
      return 'LED Pattern Selector';
    case 'TH_AMBIENT':
      return 'Ambient Sensor';
    case 'TH_AMBIENT_CTRL':
      return 'Ambient Controller';
    case 'TH_DATAGATEWAY':
      return 'Data Gateway';
    case 'TH_NUTRIENT_SENSOR':
      return 'Nutrient Sensor';
    default:
      return '(Unknown Device)';
  }
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';

// material
import { Box, IconButton, InputBase, Divider } from '@mui/material';

import {
  AddCircleRounded as AddCircleRoundedIcon,
  RemoveCircleRounded as RemoveCircleRoundedIcon
} from '@material-ui/icons';

InputNumber.propTypes = {
  defaultValue: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  decimalPlace: PropTypes.number,
  isInteger: PropTypes.bool,
  onChange: PropTypes.func,
  justifyContent: PropTypes.string,
  fontSize: PropTypes.number
};
export default function InputNumber({
  defaultValue,
  min,
  max,
  step,
  decimalPlace = 0,
  isInteger = true,
  onChange = () => { },
  justifyContent = 'center',
  fontSize = 40
}) {
  const [value, setValue] = useState(defaultValue);
  const _setValue = (newVal) => {
    onChange(newVal);
    setValue(newVal);
  };

  const valifyRange = (_value) => {
    _value = isInteger ? Number(parseInt(_value, 10)) : Number(parseFloat(_value).toFixed(decimalPlace));

    if (_value > max) _value = max;
    if (_value < min) _value = min;

    return _value;
  };
  const onIncrease = () => _setValue(valifyRange(value + step));
  const onDecrease = () => _setValue(valifyRange(value - step));

  // useEffect(() => {
  //   return () => {};
  // }, []);

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        justifyContent,
        p: 0.5,
        borderRadius: 8,
        boxShadow: 3,
      }}
    >
      <IconButton onClick={onDecrease}>
        <RemoveCircleRoundedIcon sx={{ fontSize }} />
      </IconButton>
      <Divider sx={{ height: 56, m: 0.5 }} orientation="vertical" />
      <InputBase
        type="number"
        placeholder=""
        inputProps={{ style: { fontSize, textAlign: 'center' }, min, max, step }}
        value={value.toFixed(decimalPlace)}
        onChange={(e) => {
          _setValue(valifyRange(e.target.value));
        }}
      />
      <Divider sx={{ height: 56, m: 0.5 }} orientation="vertical" />
      <IconButton onClick={onIncrease}>
        <AddCircleRoundedIcon sx={{ fontSize }} />
      </IconButton>
    </Box>
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { sentenceCase } from 'change-case';

// material
import { styled, useTheme } from '@mui/material/styles';
import { Box, Card, Grid, Container, Typography, Paper } from '@mui/material';

// components
import Label from '../components/Label';
import Page from '../components/Page';
import { DeviceAmbientStatus } from '../components/_dashboard/devices';
import { StatusBadge } from '../components/_dashboard/widgets';
import {
  AmbientSettings,
  DoserSettings,
  SwitchSettings,
  DimmerSettings,
  AmbientAlarms,
} from '../components/_dashboard/widgets/modals';
import apiHandler from '../api/axios-api';

const DimmerSettingsMemo = React.memo(DimmerSettings);
const SwitchSettingsMemo = React.memo(SwitchSettings);

const ContainerStyle = styled(Card)(({ theme }) => ({
  // boxShadow: 'none',
  // backgroundColor: theme.palette.grey[100]ม
  textAlign: 'center',
  padding: theme.spacing(3, 0)
}));
const DeviceContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

// const useStyles = makeStyles((theme) =>
//   createStyles({
//     root: {
//       boxShadow: 'none',
//       textAlign: 'center',
//       padding: theme.spacing(3, 3),
//       color: theme.palette.error.darker,
//       backgroundColor: theme.palette.grey[100]
//     }
//   })
// );

export default function GroupDashboard() {
  const theme = useTheme();
  const { groupId } = useParams();
  const [state, setState] = useState({
    group: {},
    groupDevices: {
      groupId: null,
      devices: []
    },
    renderDevices: {
      TH_AMBIENT: [],
      TH_DOSER: [],
      TH_DIMMER: [],
      TH_SWITCH: []
    },
    ambientAvgs: {
      temperature: 0,
      humidity: 0,
      co2: 0,
      intensity: 0
    },
    ambientIds: [],
    ambientCtrIds: [],
    // alarmGroup: {
    //   // AMBIENT: ['TEMPERATURE', 'HUMIDITY', 'CO2', 'INTENSITY'],
    //   AMBIENT: [],
    // }
  });

  const fetchData = useCallback(async () => {
    const groups = await apiHandler('device-groups');
    const groupList = groups.data.results;
    const devices = await apiHandler('devices');
    const groupedDevices = groupingDeviceList(devices.data.results);

    const _groupDevicesById = getGroupedDeviceById(groupId, groupedDevices);
    const _group = groupList.filter((obj) => Number(obj.groupId) === Number(groupId))[0];
    const _ambientIds = [];
    const _ambientCtrIds = [];

    const _ambientAvgs = {
      temperature: 0,
      humidity: 0,
      co2: 0,
      intensity: 0,
      alarm_temperature: false,
      alarm_humidity: false,
      alarm_co2: false,
      alarm_intensity: false,
    };

    const _renderDevices = {
      TH_AMBIENT: [],
      TH_DOSER: [],
      TH_DIMMER: [],
      TH_SWITCH: []
    };

    _groupDevicesById.devices.map((el) => {
      switch (el.productType) {
        case 'TH_AMBIENT':
          _renderDevices.TH_AMBIENT.push(el);
          _ambientIds.push(el.deviceId);
          break;
        case 'TH_DOSER':
          _renderDevices.TH_DOSER.push(el);
          break;
        case 'TH_DIMMER':
          _renderDevices.TH_DIMMER.push(el);
          break;
        case 'TH_SWITCH':
          _renderDevices.TH_SWITCH.push(el);
          break;
        case 'TH_AMBIENT_CTRL':
          _ambientCtrIds.push(el.deviceId);
          break;

        default:
          break;
      }
      return el;
    });

    // console.log(_renderDevices.TH_AMBIENT);
    // console.log(state.alarmGroup.AMBIENT);

    // _ambientAvgs.temperature =
    //   _renderDevices.TH_AMBIENT.reduce((total, next) => total + next.data.status.temperature, 0) /
    //   _renderDevices.TH_AMBIENT.length;

    // let _ambient_temperature_length = _renderDevices.TH_AMBIENT.length;
    // _ambientAvgs.temperature =
    //   _renderDevices.TH_AMBIENT.reduce((total, next) => {
    //     if(next.data.status.temperature === null) {
    //       _ambient_temperature_length--;
    //     }

    //     return total + next.data.status.temperature;
    //   }, 0) /
    //   _ambient_temperature_length;
    _ambientAvgs.temperature = reduceFilter(_renderDevices.TH_AMBIENT, 'temperature');

    // _ambientAvgs.humidity =
    //   _renderDevices.TH_AMBIENT.reduce((total, next) => total + next.data.status.humidity, 0) /
    //   _renderDevices.TH_AMBIENT.length;
    _ambientAvgs.humidity = reduceFilter(_renderDevices.TH_AMBIENT, 'humidity');

    // _ambientAvgs.co2 =
    //   _renderDevices.TH_AMBIENT.reduce((total, next) => total + next.data.status.co2, 0) /
    //   _renderDevices.TH_AMBIENT.length;
      _ambientAvgs.co2 = reduceFilter(_renderDevices.TH_AMBIENT, 'co2');

    // _ambientAvgs.intensity =
    //   _renderDevices.TH_AMBIENT.reduce((total, next) => total + next.data.status.intensity, 0) /
    //   _renderDevices.TH_AMBIENT.length;
      _ambientAvgs.intensity = reduceFilter(_renderDevices.TH_AMBIENT, 'intensity');

    _ambientAvgs.alarm_temperature =
      _renderDevices.TH_AMBIENT.reduce((total, next) => total || (next.data.alarms ?? []).includes('TEMPERATURE'), false);

    _ambientAvgs.alarm_humidity =
      _renderDevices.TH_AMBIENT.reduce((total, next) => total || (next.data.alarms ?? []).includes('HUMIDITY'), false);

    _ambientAvgs.alarm_co2 =
      _renderDevices.TH_AMBIENT.reduce((total, next) => total || (next.data.alarms ?? []).includes('CO2'), false);

    _ambientAvgs.alarm_intensity =
      _renderDevices.TH_AMBIENT.reduce((total, next) => total || (next.data.alarms ?? []).includes('INTENSITY'), false);

    setState({
      group: _group,
      groupDevices: _groupDevicesById,
      renderDevices: _renderDevices,
      ambientAvgs: _ambientAvgs,
      ambientCtrIds: _ambientCtrIds,
      ambientIds: _ambientIds,
    });
  }, [groupId]);

  const reduceFilter = (data, key) => {
    let _length = data.length;

    return data.reduce((total, next) => {
        if(next.data.status[key] === null) {
          _length--;
          return total;
        }
        return total + next.data.status[key];
      }, 0) /
      _length;
  }

  /**
   * _groupId = null  // Unknow group
   * _groupId = 0     // All devices
   *
   */
  const groupingDeviceList = (devicesData) => {
    let newData = [];
    const hash = devicesData.reduce((result, item) => {
      let _groupId = item.groupId;
      if (_groupId === undefined) {
        _groupId = null;
      }

      return (
        typeof result[_groupId] !== 'undefined'
          ? result[_groupId].push(item)
          : (result[_groupId] = [item]),
        result
      );
    }, {});

    newData.push({
      groupId: 0,
      devices: devicesData
    });

    newData = newData.concat(
      Object.keys(hash).map((id) => {
        const _id = id !== 'null' ? Number(id) : null;
        return {
          groupId: _id,
          devices: hash[_id]
        };
      })
    );

    return newData;
  };

  const getGroupedDeviceById = (groupId, groupedDevices) => {
    // null or string
    if (typeof groupId === 'object') {
      groupId = null;
    }

    const result = groupedDevices.filter((obj) => Number(obj.groupId) === Number(groupId));

    // Not found
    if (!result.length) {
      return {
        groupId,
        devices: []
      };
    }

    return result[0];
  };

  useEffect(() => {
    fetchData();
    const intervalLoop = setInterval(() => {
      fetchData();
    }, 3000);

    return () => {
      clearInterval(intervalLoop);
    };
  }, [fetchData]);

  // console.log(state);

  const { ambientAvgs, renderDevices, ambientIds, ambientCtrIds } = state;

  return (
    <Page title="Summary | Agrio">
      <Container maxWidth="xl">
        <Box sx={{ pb: 2 }}>
          <Typography variant="h3">{state.group.name ?? 'Loading...'}</Typography>
        </Box>
        {state.group.name ? <Grid container spacing={3} px={0} mb={3}>
          <Grid item xs={12}>
            <ContainerStyle>
              <Grid container spacing={3} px={3}>
                <Grid item xs={6} sx={{ textAlign: 'left' }}>
                  <Typography variant="h4">Environments</Typography>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                  <AmbientAlarms deviceIds={ambientIds} sx={{mr: 0.5}} />
                  <AmbientSettings deviceIds={ambientCtrIds} />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  <DeviceAmbientStatus
                    measureName="Temperature"
                    unitName="°C"
                    value={ambientAvgs.temperature}
                    valueFixed={1}
                    isAlert={ambientAvgs.alarm_temperature}
                  />
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <DeviceAmbientStatus
                    measureName="Humidity"
                    unitName="%RH"
                    value={ambientAvgs.humidity}
                    valueFixed={1}
                    isAlert={ambientAvgs.alarm_humidity}
                  />
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <DeviceAmbientStatus
                    measureName="CO2"
                    unitName="ppm"
                    value={ambientAvgs.co2}
                    valueFixed={0}
                    isAlert={ambientAvgs.alarm_co2}
                  />
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                  <DeviceAmbientStatus
                    measureName="Light Intensity"
                    unitName="lux"
                    value={ambientAvgs.intensity}
                    valueFixed={0}
                    isAlert={ambientAvgs.alarm_intensity}
                  />
                </Grid>
              </Grid>
            </ContainerStyle>
          </Grid>
        </Grid>
          : <></>
        }

        <Grid container spacing={3} px={0}>
          {renderDevices.TH_DOSER.length > 0 && (
            <Grid item md={6}>
              <ContainerStyle>
                <Grid container rowSpacing={2} columnSpacing={2} px={3}>
                  <Grid item xs={10} sx={{ textAlign: 'left' }}>
                    <Typography variant="h4">Doser</Typography>
                  </Grid>
                  {renderDevices.TH_DOSER.map((_device, _indexDevice) => {
                    const _temp = _device.data.status.temperature ? _device.data.status.temperature.toFixed(1) : '-';
                    const _ec = _device.data.status.ec ? (_device.data.status.ec / 1000).toFixed(1) : '-';
                    const _ph = _device.data.status.ph ? _device.data.status.ph.toFixed(1) : '-';

                    return (
                      <Grid key={_indexDevice} item xs={12}>
                        <DeviceContainer elevation={0}>
                          <Grid container spacing={2} px={0} alignItems="center">
                            <Grid item xs={2} sx={{ whiteSpace: 'nowrap' }}>
                              <Box
                                textAlign="center"
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                              >
                                <Typography variant="h5">{_device.deviceName}</Typography>
                              </Box>
                              <Box textAlign="center">
                                <Label
                                  variant="ghost"
                                  color={(_device.online === false && 'error') || 'success'}
                                >
                                  {sentenceCase(_device.online ? 'online' : 'offline')}
                                </Label>
                              </Box>
                            </Grid>
                            <Grid item xs={3}>
                              <StatusBadge
                                value={_temp}
                                unitName="°C"
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <StatusBadge
                                value={_ec}
                                unitName="mS/cm"
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <StatusBadge
                                value={_ph}
                                unitName="pH"
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Grid container spacing={1} px={0} alignItems="center">
                                <Grid item xs={12}>
                                  <DoserSettings deviceId={_device.deviceId} />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </DeviceContainer>
                      </Grid>
                    );
                  })}
                </Grid>
              </ContainerStyle>
            </Grid>
          )}

          {renderDevices.TH_DIMMER.length > 0 && (
            <Grid item md={6}>
              <ContainerStyle>
                <Grid container rowSpacing={2} columnSpacing={2} px={3}>
                  <Grid item xs={12} sx={{ textAlign: 'left' }}>
                    <Typography variant="h4">Growlight</Typography>
                  </Grid>

                  {renderDevices.TH_DIMMER.map((_device, _indexDevice) => (
                    <Grid key={_indexDevice} item xs={12}>
                      <DeviceContainer elevation={0}>
                        <Grid container spacing={2} px={0} alignItems="center">
                          <Grid item xs={2} sx={{ whiteSpace: 'nowrap' }}>
                            <Box
                              textAlign="center"
                              sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                            >
                              <Typography variant="h5">{_device.deviceName}</Typography>
                            </Box>
                            <Box textAlign="center">
                              <Label
                                variant="ghost"
                                color={(_device.online === false && 'error') || 'success'}
                              >
                                {sentenceCase(_device.online ? 'online' : 'offline')}
                              </Label>
                            </Box>
                          </Grid>
                          <Grid item xs={9}>
                            <Grid container spacing={1} alignItems="center">
                              {_device.data.status.map((_channel, _indexCh) => {
                                const chResult = [];
                                chResult.push(
                                  <Grid key={_indexCh} item xs={3}>
                                    <StatusBadge
                                      value={_channel.power ? 'ON' : 'OFF'}
                                      backgroundColor={theme.palette.warning.lighter}
                                    />
                                  </Grid>
                                );

                                return chResult;
                              })}
                              {_device.data.status.length < 4 ? (
                                <Grid item xs={3 * (4 - _device.data.status.length)} />
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                          <Grid item xs={1}>
                            <Grid container spacing={1} px={0} alignItems="center">
                              <Grid item xs={12}>
                                <DimmerSettingsMemo deviceId={_device.deviceId} />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </DeviceContainer>
                    </Grid>
                  ))}
                </Grid>
              </ContainerStyle>
            </Grid>
          )}

          {renderDevices.TH_SWITCH.length > 0 && (
            <Grid item md={6}>
              <ContainerStyle>
                <Grid container rowSpacing={2} columnSpacing={2} px={3}>
                  <Grid item xs={12} sx={{ textAlign: 'left' }}>
                    <Typography variant="h4">Switch</Typography>
                  </Grid>

                  {renderDevices.TH_SWITCH.map((_device, _indexDevice) => (
                    <Grid key={_indexDevice} item xs={12}>
                      <DeviceContainer elevation={0}>
                        <Grid container spacing={2} px={0} alignItems="center">
                          <Grid item xs={2} sx={{ whiteSpace: 'nowrap' }}>
                            <Box
                              textAlign="center"
                              sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                            >
                              <Typography variant="h5">{_device.deviceName}</Typography>
                            </Box>
                            <Box textAlign="center">
                              <Label
                                variant="ghost"
                                color={(_device.online === false && 'error') || 'success'}
                              >
                                {sentenceCase(_device.online ? 'online' : 'offline')}
                              </Label>
                            </Box>
                          </Grid>
                          <Grid item xs={9}>
                            <Grid container spacing={1} alignItems="center">
                              {_device.data.status.map((_channel, _indexCh) => {
                                const chResult = [];
                                chResult.push(
                                  <Grid key={_indexCh} item xs={3}>
                                    <StatusBadge
                                      value={_channel.power ? 'ON' : 'OFF'}
                                      backgroundColor={theme.palette.warning.lighter}
                                    />
                                  </Grid>
                                );

                                return chResult;
                              })}
                              {_device.data.status.length < 4 ? (
                                <Grid item xs={3 * (4 - _device.data.status.length)} />
                              ) : (
                                ''
                              )}
                            </Grid>
                          </Grid>
                          <Grid item xs={1}>
                            <Grid container spacing={1} px={0} alignItems="center">
                              <Grid item xs={12}>
                                <SwitchSettingsMemo deviceId={_device.deviceId} />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </DeviceContainer>
                    </Grid>
                  ))}
                </Grid>
              </ContainerStyle>
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
}

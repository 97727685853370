import apiHandler from './axios-api';
import configApi from './configs';

class AgrioBackendService {
  constructor() {
    this.apiServiceInstance = null;
    // console.log('Create class [AgrioBackendService]');
  }

  async getApiServiceInstance() {
    if (!this.apiServiceInstance) {
      this.apiServiceInstance = apiHandler;
    }

    return this.apiServiceInstance;
  }

  // get all devices
  async getDeviceList() {
    const apiService = await this.getApiServiceInstance();
    const result = await apiService.request({
      url: configApi.deivces.list
    });

    return result.data;
  }

  // get device detail
  async getDeviceInfo(deviceId) {
    const apiService = await this.getApiServiceInstance();
    const result = await apiService.request({
      url: `${configApi.deivces.info}/${deviceId}`
    });

    return result.data;
  }

  // update device
  async updateDevice(deviceId, attributes) {
    const apiService = await this.getApiServiceInstance();
    const bodyParameters = attributes;
    const result = await apiService.request({
      method: 'patch',
      url: `${configApi.deivces.update}/${deviceId}`,
      data: bodyParameters
    });

    return result.data;
  }

  // get device settings
  async getDeviceSettings(deviceId) {
    const apiService = await this.getApiServiceInstance();
    const result = await apiService.request({
      url: `${configApi.deivces.info}/${deviceId}/settings`,
    });

    return result.data;
  }

  // update device settings
  async updateDeviceSettings(deviceId, attributes) {
    const apiService = await this.getApiServiceInstance();
    const bodyParameters = attributes;
    const result = await apiService.request({
      method: 'patch',
      url: `${configApi.deivces.update}/${deviceId}/settings`,
      data: bodyParameters
    });

    return result.data;
  }

  // get all available groups
  async getDeviceGroupList() {
    const apiService = await this.getApiServiceInstance();
    const result = await apiService.request({
      url: configApi.deivceGroups.list
    });

    result.data.results.unshift(
      {
        groupId: null,
        name: 'Ungroup Devices'
      },
      {
        groupId: 0,
        name: 'All Devices'
      }
    );

    return result.data;
  }

  // get group detail
  async getGroupInfo(groupId) {
    const apiService = await this.getApiServiceInstance();
    const result = await apiService.request({
      url: `${configApi.deivceGroups.info}/${groupId}`
    });

    return result.data;
  }

  // update device groups
  async updateDeviceGroups(groupId, attributes) {
    const apiService = await this.getApiServiceInstance();
    const bodyParameters = attributes;
    const result = await apiService.request({
      method: 'patch',
      url: `${configApi.deivceGroups.update}/${groupId}`,
      data: bodyParameters
    });

    return result.data;
  }

  // get user profile
  async getUserProfile() {
    const apiService = await this.getApiServiceInstance();
    const result = await apiService.request({
      url: configApi.user.info
    });

    return result.data;
  }

  // update user profile
  async updateUserProfile(attributes) {
    const apiService = await this.getApiServiceInstance();
    const bodyParameters = attributes;
    const result = await apiService.request({
      method: 'patch',
      url: configApi.user.update,
      data: bodyParameters
    });

    return result.data;
  }

  // authentication account login
  // async authAccountLogin(attributes) {
  //   const apiService = await this.getApiServiceInstance();
  //   const bodyParameters = attributes;
  //   const result = await apiService.request({
  //     method: 'post',
  //     url: configApi.authentication.accountLogin,
  //     data: bodyParameters
  //   });

  //   apiHandler.defaults.headers['Authorization'] = `JWT ${result.data.access}`;
  //   apiHandler.defaults.headers['Authorization'] = `JWT ${result.data.access}`;
  //   localStorage.setItem('access_token', result.data.access);
  //   localStorage.setItem('refresh_token', result.data.refresh);

  //   return result.data;
  // }
}

export default AgrioBackendService;

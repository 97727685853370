import PropTypes from 'prop-types';
// import React, { useState, useEffect } from 'react';

// material
import { Grid } from '@material-ui/core';
// components
import { DeviceNutrientStatus, DeviceNutrientHistorical } from '../_dashboard/devices';

NutrientSensor.propTypes = {
  deviceId: PropTypes.string,
  data: PropTypes.any.isRequired
};

export default function NutrientSensor({ deviceId, data }) {
  return (
    <>
      <Grid item xs={12} sm={3} md={3}>
        <DeviceNutrientStatus measureName="TDS" unitName="ppm" value={Number(data.tds)} />
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <DeviceNutrientStatus
          measureName="EC"
          unitName="mS/cm"
          value={Number.parseFloat(data.ec / 1000).toFixed(1)}
        />
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <DeviceNutrientStatus measureName="pH" unitName="pH" value={Number(data.ph)} />
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <DeviceNutrientStatus
          measureName="Temperature"
          unitName="°C"
          value={Number(data.temperature)}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <DeviceNutrientHistorical deviceId={deviceId} showEvent={false} />
      </Grid>
    </>
  );
}

const config = {
  // API_BASE_URL: 'https://agrio.agrowdev.com',
  // API_BASE_URL: 'https://agrio.agrowlab.com',
  // API_BASE_URL: 'http://127.0.0.1:8000',

  // API_BASE_URL: 'https://api.agrio.agrowdev.com',
  // API_BASE_URL:'https://api.agrio.agrowlab.com',
  // API_BASE_URL:'http://10.6.0.40:8000',
  API_BASE_URL: '',

  authentication: {
    sociaLogin: '/signin',
    accountLogin: '/token/obtain',
    refreshToken: '/token/refresh'
  },
  user: {
    info: '/profile',
    update: '/profile'
  },
  deivces: {
    list: '/devices',
    info: '/devices',
    update: '/devices'
  },
  deivceGroups: {
    list: '/device-groups',
    info: '/device-groups',
    update: '/device-groups'
  },
  formulas: {
    list: '/formulas',
    info: '/formulas',
    update: '/formulas'
  }
};

module.exports = config;

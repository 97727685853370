import { withStyles } from '@mui/styles';
import { Chip } from '@mui/material';

// const useStyles = makeStyles({
//   trContainer: {
//     padding: '30px 0 0',
//     height: '70px',
//     boxSizing: 'border-box'
//   }
// });

// const StyleChip = withStyles((theme) => ({
const BigChip = withStyles(() => ({
  root: {
    fontSize: 22,
    fontWeight: 'bold'
  }
}))(Chip);

export default BigChip;

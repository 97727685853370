import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Grid,
  Typography,
  Modal,
  Collapse
} from '@mui/material';

// material
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import BackendService from '../../../../api/BackendService';
import { GetIcon } from '../../../Utils';
import { InputNumber, IOSSwitch, BigChip } from '..';

// ----------------------------------------------------------------------

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  p: 3,
  borderRadius: 8,
  boxShadow: 10,
};

const buttonStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'end',
  cursor: 'pointer'
};

const backendService = new BackendService();
AmbientAlarms.propTypes = {
  deviceIds: PropTypes.arrayOf(PropTypes.string).isRequired
};
export default function AmbientAlarms({ deviceIds, ...others }) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    device: {},
    alarms: {}
  });
  const { alarms } = state;

  // useEffect(() => {
  //   return () => {};
  // }, []);

  const handleOpen = async () => {
    await fetchData();
    return setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return false;
    }
    return setOpen(false);
  };

  const fetchData = async () => {
    const _tmpAlarms = [];

    await Promise.all(
      deviceIds.map(async (_deviceId) => {
        const _settings = await backendService.getDeviceSettings(_deviceId);
        const _al = _settings.alarms ? _settings.alarms : [];
        _tmpAlarms.push(_al);
      })
    );

    // Merge multiple Ambients Control
    const _alarms = _tmpAlarms.flat().reduce((acc, curr) => {
      if (!acc[curr.parameter]) {
        acc[curr.parameter] = {
          ...curr
        };
      } else {
        acc[curr.parameter] = {
          ...acc[curr.parameter],
          ...curr
        };
      }

      return acc;
    }, {});

    // console.log(Object.values(_alarms));
    // console.log(_alarms);

    setState({
      ...state,
      alarms: _alarms
    });
  };

  const updateDeviceSettings = (params) => {
    deviceIds.map((deviceId) => backendService.updateDeviceSettings(deviceId, params));

    setState({
      ...state,
      alarms,
    });
  };

  const getRenderData = (_alaram) => {
    const result = {
      key: _alaram.parameter,
      name: _alaram.parameter,
      valueLow: Number(parseFloat(_alaram.low).toFixed(0)),
      valueHigh: Number(parseFloat(_alaram.high).toFixed(0)),
    };

    switch (_alaram.parameter) {
      case 'TEMPERATURE':
        result.name = 'Temperature';
        result.unit = '°C';
        result.inputMin = -40;
        result.inputMax = 120;
        result.inputStep = 1;
        break;

      case 'CO2':
        result.name = 'CO2';
        result.unit = 'ppm';
        result.inputMin = 0;
        result.inputMax = 10000;
        result.inputStep = 100;
        break;

      case 'HUMIDITY':
        result.name = 'Humidity';
        result.unit = '%RH';
        result.inputMin = 0;
        result.inputMax = 100;
        result.inputStep = 1;
        break;

      // case 'INTENSITY':
      //   result.name = 'Light Intensity';
      //   result.unit = 'Lux';
      //   result.inputMin = 0;
      //   result.inputMax = 10000;
      //   result.inputStep = 100;
      //   break;

      default:
        break;
    }

    return result;
  };

  // console.log(state);
  return (
    <>
      <Box sx={[buttonStyle, others.sx]}>
        <GetIcon icon={<NotificationsActiveIcon />} onClick={handleOpen} />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <Box sx={modalStyle}>
          <Grid container spacing={0} sx={{ pt: 1, pb: 2 }} alignItems="center">
            <Grid item xs={6}>
              <Typography id="modal-modal-title" variant="h4">
                Alarm Settings
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <GetIcon icon={<CancelRoundedIcon sx={{ fontSize: 40 }} />} onClick={handleClose} />
            </Grid>
          </Grid>

          {Object.keys(alarms)
            .sort()
            .map((key, index) => {
              const _alaram = alarms[key];
              const renderObj = getRenderData(_alaram);

              return (
                <Card key={index} sx={{ mb: 2, mx: 3 }}>
                  <CardHeader
                    title={`${renderObj.name} Alarm`}
                    action={
                      <>
                        <IOSSwitch
                          checked={_alaram.enable}
                          onChange={(e) => {
                            alarms[key].enable = e.target.checked;
                            alarms[key].power = e.target.checked;

                            updateDeviceSettings({
                              alarms: [alarms[key]]
                            });
                          }}
                        />
                      </>
                    }
                  />
                  <CardContent sx={{ pt: 0 }}>
                    <Collapse in={_alaram.enable} timeout="auto" unmountOnExit>
                      <Grid container spacing={2} pl={3} alignItems="center">
                        <Grid item xs={4} />
                        <Grid item xs={4} textAlign="center">
                          <BigChip label={`Low (${renderObj.unit})`} color="default" />
                        </Grid>
                        <Grid item xs={4} textAlign="center">
                          <BigChip label={`High (${renderObj.unit})`} color="default" />
                        </Grid>
                      </Grid>
                      <Grid container mt={1} spacing={2} pl={3} alignItems="center">
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={4}>
                          <InputNumber
                            defaultValue={renderObj.valueLow}
                            min={renderObj.inputMin}
                            max={renderObj.inputMax}
                            step={renderObj.inputStep}
                            onChange={(_value) => {
                              alarms[key].low = _value;
                              updateDeviceSettings({
                                alarms: [alarms[key]]
                              });
                            }}
                            justifyContent="center"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <InputNumber
                            defaultValue={renderObj.valueHigh}
                            min={renderObj.inputMin}
                            max={renderObj.inputMax}
                            step={renderObj.inputStep}
                            onChange={(_value) => {
                              alarms[key].high = _value;
                              updateDeviceSettings({
                                alarms: [alarms[key]]
                              });
                            }}
                            justifyContent="center"
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </CardContent>
                </Card>
              );
            })}
        </Box>
      </Modal>
    </>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@mui/material';
import { withStyles } from '@mui/styles';
import { LocalizationProvider, MobileTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

ModalTimePicker.propTypes = {
  fontSize: PropTypes.number,
  textAlign: PropTypes.string,
  justifyContent: PropTypes.string
};

export default function ModalTimePicker({
  fontSize = 40,
  textAlign = 'center',
  justifyContent = 'flex-start',
  ...props
}) {
  const StyledTextField = withStyles(() => ({
    root: {
      '& .MuiInputBase-root': {
        '& input': {
          fontSize,
          textAlign,
          padding: '4px 0',
          cursor: 'pointer',
          width: '128px'
        },
        '& fieldset': {
          border: '0 none'
        }
      }
    }
  }))(TextField);

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent,
        p: 0.5,
        borderRadius: 8,
        boxShadow: 3,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileTimePicker {...props} renderInput={(params) => <StyledTextField {...params} />} />
      </LocalizationProvider>
    </Box>
  );
}

import PropTypes from 'prop-types';
// material
import { styled } from '@material-ui/core/styles';
import { Paper, Typography, Box } from '@material-ui/core';

// ----------------------------------------------------------------------

const DeviceBadge = styled(Paper)(({ theme, color, bgcolor }) => {
  if (!color) {
    color = theme.palette.text.secondary;
  }
  if (!bgcolor) {
    bgcolor = theme.palette.info.lighter;
  }
  return {
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color,
    backgroundColor: bgcolor
  };
});

// ----------------------------------------------------------------------
StatusBadge.propTypes = {
  value: PropTypes.any,
  unitName: PropTypes.string,
  measureName: PropTypes.string,
  color: PropTypes.string,
  backgroundColor: PropTypes.string
};

export default function StatusBadge({
  value = 'N/A',
  unitName = '',
  measureName = null,
  color,
  backgroundColor
}) {
  return (
    <DeviceBadge elevation={0} color={color} bgcolor={backgroundColor}>
      <Box sx={{
        minHeight: "80px",
        flexDirection: 'column',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}>
        <Typography variant="h4">
          {value}
        </Typography>
        <Typography variant="subtitle2">
          {unitName}
        </Typography>
        {measureName ? (
          <Typography variant="caption" sx={{ opacity: 0.72 }}>
            {measureName}
          </Typography>
        ) : (
          <></>
        )}
      </Box>
    </DeviceBadge>
  );
}

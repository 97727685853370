import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Grid,
  Typography,
  Modal,
  Collapse
} from '@mui/material';

// material
import SettingsIcon from '@material-ui/icons/Settings';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';

import BackendService from '../../../../api/BackendService';
import { GetIcon } from '../../../Utils';
import { InputNumber, IOSSwitch, BigChip } from '..';

// ----------------------------------------------------------------------

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  p: 3,
  borderRadius: 8,
  boxShadow: 10,
};

const buttonStyle = {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'end',
  cursor: 'pointer'
};

const backendService = new BackendService();
AmbientSettings.propTypes = {
  deviceIds: PropTypes.arrayOf(PropTypes.string).isRequired
};
export default function AmbientSettings({ deviceIds, ...others }) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    device: {},
    setpoints: {}
  });
  // const [controls, setControls] = useState({
  //   temperature: false,
  //   co2: false,
  //   humidity: false,
  //   intensity: false,
  // });

  const { setpoints } = state;

  const handleOpen = async () => {
    await fetchData();
    return setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return false;
    }
    return setOpen(false);
  };

  const fetchData = async () => {
    const _tmpSetpoints = [];

    await Promise.all(
      deviceIds.map(async (_deviceId) => {
        const _settings = await backendService.getDeviceSettings(_deviceId);
        const _sp = _settings.setpoints ? _settings.setpoints : [];
        _tmpSetpoints.push(_sp);
      })
    );

    // Merge multiple Ambients Control
    const _setpoints = _tmpSetpoints.flat().reduce((acc, curr) => {
      if (!acc[curr.parameter]) {
        acc[curr.parameter] = {
          ...curr
        };
      } else {
        acc[curr.parameter] = {
          ...acc[curr.parameter],
          ...curr
        };
      }

      return acc;
    }, {});

    // console.log(Object.values(_setpoints));
    // console.log(_setpoints);

    setState({
      ...state,
      setpoints: _setpoints
    });
  };

  const updateDeviceSettings = (params) => {
    deviceIds.map((deviceId) => backendService.updateDeviceSettings(deviceId, params));

    setState({
      ...state,
      setpoints,
    });
  };

  const processSetpoint = (key, value, variant) => {
    setpoints[key].min = value - variant;
    setpoints[key].max = value + variant;

    return setpoints[key];
  };

  const getRenderData = (_setpoint) => {
    const result = {
      key: _setpoint.parameter,
      name: _setpoint.parameter,
      value: Number(parseFloat((_setpoint.max + _setpoint.min) / 2).toFixed(0)),
      variant: Number(parseFloat((_setpoint.max - _setpoint.min) / 2).toFixed(0))
    };

    switch (_setpoint.parameter) {
      case 'TEMPERATURE':
        result.name = 'Temperature';
        result.unit = '°C';
        result.inputMin = 18;
        result.inputMax = 32;
        result.inputStep = 1;
        result.variantMin = 0;
        result.variantMax = 10;
        result.variantStep = 1;
        break;

      case 'CO2':
        result.name = 'CO2';
        result.unit = 'ppm';
        result.inputMin = 0;
        result.inputMax = 10000;
        result.inputStep = 100;
        result.variantMin = 0;
        result.variantMax = 1000;
        result.variantStep = 100;
        break;

      case 'HUMIDITY':
        result.name = 'Humidity';
        result.unit = '%RH';
        result.inputMin = 0;
        result.inputMax = 100;
        result.inputStep = 1;
        result.variantMin = 0;
        result.variantMax = 10;
        result.variantStep = 1;
        break;

      case 'INTENSITY':
        result.name = 'Light Intensity';
        result.unit = 'Lux';
        result.inputMin = 0;
        result.inputMax = 10000;
        result.inputStep = 100;
        result.variantMin = 0;
        result.variantMax = 1000;
        result.variantStep = 100;
        break;

      default:
        break;
    }

    return result;
  };

  // useEffect(() => {
  //   return () => {};
  // }, []);

  // console.log(state);
  return (
    <>
      <Box sx={[buttonStyle, others.sx]}>
        <GetIcon icon={<SettingsIcon />} onClick={handleOpen} />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <Box sx={modalStyle}>
          <Grid container spacing={0} sx={{ pt: 1, pb: 2 }} alignItems="center">
            <Grid item xs={6}>
              <Typography id="modal-modal-title" variant="h4">
                Ambient Settings
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <GetIcon icon={<CancelRoundedIcon sx={{ fontSize: 40 }} />} onClick={handleClose} />
            </Grid>
          </Grid>

          {Object.keys(setpoints)
            .sort()
            .map((key, index) => {
              const _setpoint = setpoints[key];
              const renderObj = getRenderData(_setpoint);

              return (
                <Card key={index} sx={{ mb: 2, mx: 3 }}>
                  <CardHeader
                    title={`${renderObj.name} Controller`}
                    action={
                      <>
                        <IOSSwitch
                          checked={_setpoint.enable}
                          onChange={(e) => {
                            setpoints[key].enable = e.target.checked;
                            setpoints[key].power = e.target.checked;

                            updateDeviceSettings({
                              setpoints: [setpoints[key]]
                            });
                          }}
                        />
                      </>
                    }
                  />
                  <CardContent sx={{ pt: 0 }}>
                    <Collapse in={_setpoint.enable} timeout="auto" unmountOnExit>
                      <Grid container spacing={2} pl={3} alignItems="center">
                        <Grid item xs={4} />
                        <Grid item xs={4} textAlign="center">
                          <BigChip label={renderObj.unit} color="default" />
                        </Grid>
                        <Grid item xs={4} textAlign="center">
                          <BigChip label="Variation" color="default" />
                        </Grid>
                      </Grid>
                      <Grid container mt={1} spacing={2} pl={3} alignItems="center">
                        <Grid item xs={4}>
                          <Typography id="modal-modal-title" variant="h5">
                            Target {renderObj.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <InputNumber
                            defaultValue={renderObj.value}
                            min={renderObj.inputMin}
                            max={renderObj.inputMax}
                            step={renderObj.inputStep}
                            onChange={(_value) => {
                              updateDeviceSettings({
                                setpoints: [
                                  processSetpoint(renderObj.key, _value, renderObj.variant)
                                ]
                              });
                            }}
                            justifyContent="center"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <InputNumber
                            defaultValue={renderObj.variant}
                            min={renderObj.variantMin}
                            max={renderObj.variantMax}
                            step={renderObj.variantStep}
                            onChange={(_value) => {
                              updateDeviceSettings({
                                setpoints: [processSetpoint(renderObj.key, renderObj.value, _value)]
                              });
                            }}
                            justifyContent="center"
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </CardContent>
                </Card>
              );
            })}
        </Box>
      </Modal>
    </>
  );
}

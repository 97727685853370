import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box, Grid, Container, Typography, Link } from '@material-ui/core';
// components
import {
  DeviceNutrientStatus,
} from '../components/_dashboard/devices';

import Page from '../components/Page';
import apiHandler from '../api/axios-api';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const [totalDevice, setTotalDevice] = useState(0);
  const [onlineDevice, setOnlineDevice] = useState(0);
  const [offlineDevice, setOfflineDevice] = useState(0);
  useEffect(() => {
    apiHandler("devices").then(
      (res) => {
        if ('results' in res.data) {
          let online = 0;
          let offline = 0;

          res.data.results.map((device) => {
            if (device.online === true) {
              online += 1;
            }
            else {
              offline += 1;
            }
            return null;
          });
          setTotalDevice(online + offline);
          setOnlineDevice(online);
          setOfflineDevice(offline);
        }
      }
    );
  }, []);

  return (
    <Page title="Dashboard | Agrio">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>

          <Grid item xs={12} md={6} lg={4}>
            <Link underline="none" component={RouterLink} to="/dashboard/devices">
              <DeviceNutrientStatus measureName="Total devices" unitName="" value={totalDevice} />
            </Link>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Link underline="none" component={RouterLink} to="/dashboard/devices">
              <DeviceNutrientStatus measureName="Online devices" unitName="" value={onlineDevice} />
            </Link>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Link underline="none" component={RouterLink} to="/dashboard/devices">
              <DeviceNutrientStatus measureName="Offline devices" unitName="" value={offlineDevice} />
            </Link>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={6}>
            <AppNutrientUsage />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';
import {
  Divider,
  Box,
  Grid,
  Typography,
  Modal,
  Card,
  CardHeader,
  CardContent,
  Collapse
} from '@mui/material';

// material
import SettingsIcon from '@material-ui/icons/Settings';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { GetIcon } from '../../../Utils';
import { ModalTimePicker, BigChip } from '..';

import BackendService from '../../../../api/BackendService';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  p: 3,
  borderRadius: 8,
  boxShadow: 10,
};

const modalContentStyle = {
  overflowY: 'auto',
  maxHeight: '80vh'
};

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
};

const backendService = new BackendService();
SwitchSettings.propTypes = {
  deviceId: PropTypes.string.isRequired
};

export default function SwitchSettings({ deviceId }) {
  // const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    device: {},
    settings: []
  });

  const [expand, setExpand] = useState({
    0: true,
    1: false,
    2: false,
    3: false
  });

  const { device, settings } = state;

  const handleOpen = () => {
    fetchData();
    return setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return false;
    }
    return setOpen(false);
  };

  const handleExpand = (chIndex) => {
    expand[chIndex] = !expand[chIndex];
    setExpand({ [chIndex]: expand[chIndex] });
  };

  const fetchData = async () => {
    const device = await backendService.getDeviceInfo(deviceId);
    const settings = device ? device.settings : [];

    // Check and apply schedule is array?
    Object.keys(settings).map((c) => {
      settings[c].schedule = settings[c].schedule || [];
      return settings[c];
    });

    setState({
      device,
      settings
    });
  };

  const getIconStatusColor = (isOnline) =>
    isOnline ? theme.palette.status.online : theme.palette.status.offline;

  const updateDeviceSettings = (params) => {
    backendService.updateDeviceSettings(deviceId, params);

    setState({
      ...state,
      settings
    });
  };

  const ensureScheduleItem = (schedule = {}) => {
    schedule.enable = typeof schedule.enable !== 'undefined' ? schedule.enable : true;
    schedule.time = parseInt(schedule.time || 80000, 10);
    schedule.power = typeof schedule.power !== 'undefined' ? schedule.power : true;

    return schedule;
  };

  const addScheduleItem = (chIndex, schedule = {}) => {
    settings[chIndex].schedule.push(ensureScheduleItem(schedule));
    updateDeviceSettings([settings[chIndex]]);
  };

  const removeScheduleItem = (chIndex, schIndex) => {
    settings[chIndex].schedule.splice(schIndex, 1);
    updateDeviceSettings([settings[chIndex]]);
  };

  const ScheduleItem = ({ chIndex, schIndex, schedule }) => {
    schedule = ensureScheduleItem(schedule);
    const _channel = settings[chIndex].channel.toString();

    /* renderTime for ModalTimePicker */
    const renderTime = new Date();
    renderTime.setHours((schedule.time / 10000) % 100);
    renderTime.setMinutes((schedule.time / 100) % 100);
    renderTime.setSeconds(0);

    let actionTime = schedule.time;

    const _updateDeviceSettings = () => {
      settings[chIndex].schedule[schIndex].time = actionTime;

      updateDeviceSettings([
        {
          channel: _channel,
          schedule: settings[chIndex].schedule
        }
      ]);
    };

    return (
      <Grid container mt={1} spacing={2} pl={3} alignItems="center">
        <Grid item xs={1} textAlign="left">
          <Typography id="modal-modal-title" variant="h5">
            #{schIndex + 1}
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <ModalTimePicker
            value={renderTime}
            fontSize={40}
            ampm={false}
            justifyContent="center"
            onChange={(date) => {
              if (!date) {
                return;
              }
              actionTime = date.getHours() * 10000 + date.getMinutes() * 100;
            }}
            onAccept={() => {
              _updateDeviceSettings();
            }}
            cancelText=""
          />
        </Grid>
        <Grid item xs={3} textAlign="center">
          <GetIcon
            icon={
              <PowerSettingsNewIcon
                style={{ color: getIconStatusColor(schedule.power), fontSize: 40 }}
              />
            }
            onClick={() => {
              settings[chIndex].schedule[schIndex].power = !schedule.power;

              updateDeviceSettings([
                {
                  channel: _channel,
                  schedule: settings[chIndex].schedule
                }
              ]);
            }}
          />
        </Grid>
        <Grid item xs={1} textAlign="right">
          <GetIcon
            icon={<DeleteForeverIcon sx={{ fontSize: 40, color: theme.palette.red.main }} />}
            onClick={() => removeScheduleItem(chIndex, schIndex)}
          />
        </Grid>
      </Grid>
    );
  };
  ScheduleItem.propTypes = {
    chIndex: PropTypes.number.isRequired,
    schIndex: PropTypes.number.isRequired,
    schedule: PropTypes.object
  };

  // useEffect(() => {
  //   return () => {};
  // }, []);

  // console.log(device);

  return (
    <>
      <Box sx={buttonStyle}>
        <GetIcon icon={<SettingsIcon />} onClick={handleOpen} />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <Box sx={modalStyle}>
          <Grid container spacing={0} sx={{ pt: 1, pb: 2 }} alignItems="center">
            <Grid item xs={6}>
              <Typography id="modal-modal-title" variant="h4">
                {device ? device.deviceName : '...'} Settings
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <GetIcon icon={<CancelRoundedIcon sx={{ fontSize: 40 }} />} onClick={handleClose} />
            </Grid>
          </Grid>

          <Box sx={modalContentStyle}>
            {settings.map((_setting, chIndex) => {
              const _channel = _setting.channel.toString();
              const _power = _setting.power;
              const _schedule = _setting.schedule || [];

              settings[chIndex].channel = _channel;

              return (
                <Card key={chIndex} sx={{ mb: 2, mx: 3 }}>
                  <CardHeader
                    title={`Switch ${chIndex + 1}`}
                    sx={{ pb: 2, cursor: 'pointer' }}
                    action={
                      <>
                        <GetIcon icon={expand[chIndex] ? <ExpandLessIcon /> : <ExpandMoreIcon />} />
                      </>
                    }
                    onClick={() => handleExpand(chIndex)}
                  />
                  <Collapse in={expand[chIndex]} timeout="auto" unmountOnExit>
                    <Divider variant="middle" />
                    <CardHeader
                      title="Power Control"
                      action={
                        <>
                          <GetIcon
                            icon={
                              <PowerSettingsNewIcon
                                style={{ color: getIconStatusColor(_power), fontSize: 40 }}
                              />
                            }
                            onClick={() => {
                              settings[chIndex].power = !_power;

                              updateDeviceSettings([
                                {
                                  channel: _channel,
                                  power: settings[chIndex].power
                                }
                              ]);
                            }}
                          />
                        </>
                      }
                    />
                    <CardHeader
                      title=""
                      action={
                        <>
                          <GetIcon
                            icon={
                              <AddCircleIcon
                                style={{ fontSize: 40, color: theme.palette.green.main }}
                              />
                            }
                            onClick={() => {
                              addScheduleItem(chIndex);
                            }}
                          />
                        </>
                      }
                      sx={{ pb: 2 }}
                    />
                    {_schedule.length > 0 && (
                      <CardContent sx={{ mt: -9, pr: 2 }}>
                        <Grid container spacing={2} pl={3} alignItems="center">
                          <Grid item xs={1} />
                          <Grid item xs={7} textAlign="center">
                            <BigChip label="Action Time" color="default" />
                          </Grid>
                          <Grid item xs={3} textAlign="center">
                            <BigChip label="Power" color="default" />
                          </Grid>
                          <Grid item xs={1} />
                        </Grid>
                        {_schedule.map((schedule, schIndex) => (
                          <ScheduleItem
                            key={schIndex}
                            chIndex={chIndex}
                            schIndex={schIndex}
                            schedule={schedule}
                          />
                        ))}
                      </CardContent>
                    )}
                  </Collapse>
                </Card>
              );
            })}
          </Box>
        </Box>
      </Modal>
    </>
  );
}

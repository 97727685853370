import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';

// ----------------------------------------------------------------------
// const getIcon = (name, onClick) => {
//   if (typeof onClick !== 'function') {
//     onClick = () => {};
//   }
//   return <Icon icon={name} width={22} height={22} onClick={onClick} />;
// };

GetIcon.propTypes = {
  icon: PropTypes.object,
  onClick: PropTypes.func,
  sx: PropTypes.object
};

function GetIcon(props) {
  const { onClick, icon, sx, ...other } = props;
  const newSx = { p: 0, ...sx };

  return (
    <IconButton onClick={onClick} sx={newSx} {...other}>
      {icon}
    </IconButton>
  );
}

GetIcon.defaultProps = {
  sx: {}
};

export default { GetIcon };
export { GetIcon };

import { sentenceCase } from 'change-case';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
// material
import { Box, Grid, Container, Typography } from '@material-ui/core';
// components
import Label from '../components/Label';
import Page from '../components/Page';
// import {
//   DeviceNutrientStatus,
//   DevicePowerStatus,
//   DeviceNutrientHistorical,
//   DeviceAmbientHistorical
// } from '../components/_dashboard/devices';
import { Dimmer, Switch, Doser, NutrientSensor, AmbientSensor } from '../components/devices';

import apiHandler from '../api/axios-api';
import productTypeToName from '../utils/deviceNaming';

// ----------------------------------------------------------------------

export default function DeviceDashboard() {
  const { deviceId } = useParams();
  const [online, setOnline] = useState(false);
  const [deviceName, setDeviceName] = useState('');
  const [productType, setProductType] = useState('');
  const [productVariant, setProductVariant] = useState('');
  const [currentData, setCurrentData] = useState({});

  const fetchData = useCallback(() => {
    apiHandler(`devices/${deviceId}`).then((res) => {
      setOnline(res.data.online);
      setDeviceName(res.data.deviceName);
      setProductType(res.data.productType);
      setProductVariant(res.data.productVariant);
      if (res.data.data && res.data.data.status) {
        if (res.data.productType === 'TH_DIMMER') {
          setCurrentData({
            channel0: res.data.data.status[0]
              ? {
                  power: res.data.data.status[0].power,
                  intensity: res.data.data.status[0].intensity
                }
              : null,
            channel1: res.data.data.status[1]
              ? {
                  power: res.data.data.status[1].power,
                  intensity: res.data.data.status[1].intensity
                }
              : null,
            channel2: res.data.data.status[2]
              ? {
                  power: res.data.data.status[2].power,
                  intensity: res.data.data.status[2].intensity
                }
              : null,
            channel3: res.data.data.status[3]
              ? {
                  power: res.data.data.status[3].power,
                  intensity: res.data.data.status[3].intensity
                }
              : null
          });
        }
        if (res.data.productType === 'TH_SWITCH') {
          setCurrentData({
            channel0: res.data.data.status[0] ? { power: res.data.data.status[0].power } : null,
            channel1: res.data.data.status[1] ? { power: res.data.data.status[1].power } : null,
            channel2: res.data.data.status[2] ? { power: res.data.data.status[2].power } : null,
            channel3: res.data.data.status[3] ? { power: res.data.data.status[3].power } : null
          });
        } else if (res.data.productType === 'TH_DOSER') {
          setCurrentData({
            tds: res.data.data.status.ec
              ? Number.parseFloat(res.data.data.status.ec * 0.64).toFixed(0)
              : null,
            ec: res.data.data.status.ec
              ? Number.parseFloat(res.data.data.status.ec).toFixed(0)
              : null,
            ph: res.data.data.status.ph
              ? Number.parseFloat(res.data.data.status.ph).toFixed(1)
              : null,
            temperature: res.data.data.status.temperature
              ? Number.parseFloat(res.data.data.status.temperature).toFixed(1)
              : null
          });
        } else if (res.data.productType === 'TH_NUTRIENT_SENSOR') {
          setCurrentData({
            tds: res.data.data.status.ec
              ? Number.parseFloat(res.data.data.status.ec * 0.64).toFixed(0)
              : null,
            ec: res.data.data.status.ec
              ? Number.parseFloat(res.data.data.status.ec).toFixed(0)
              : null,
            ph: res.data.data.status.ph
              ? Number.parseFloat(res.data.data.status.ph).toFixed(1)
              : null,
            temperature: res.data.data.status.temperature
              ? Number.parseFloat(res.data.data.status.temperature).toFixed(1)
              : null
          });
        } else if (res.data.productType === 'TH_AMBIENT') {
          setCurrentData({
            temperature: res.data.data.status.temperature
              ? Number.parseFloat(res.data.data.status.temperature).toFixed(1)
              : null,
            humidity: res.data.data.status.humidity
              ? Number.parseFloat(res.data.data.status.humidity).toFixed(1)
              : null,
            co2: res.data.data.status.co2
              ? Number.parseFloat(res.data.data.status.co2).toFixed(0)
              : null,
            o3: res.data.data.status.o3
              ? Number.parseFloat(res.data.data.status.o3).toFixed(0)
              : null,
            intensity: res.data.data.status.intensity
              ? Number.parseFloat(res.data.data.status.intensity).toFixed(1)
              : null
          });
        }
      }
    });
  }, [deviceId]);

  useEffect(() => {
    fetchData();
    const intervalLoop = setInterval(() => {
      fetchData();
    }, 10000);

    return function cleanup() {
      clearInterval(intervalLoop);
    };
  }, [fetchData]);

  return (
    <Page title="Dashboard | Agrio">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h3">{deviceName}</Typography>
          <Typography variant="h6">
            {productType && productTypeToName(productType, productVariant)}{' '}
          </Typography>
          <Label variant="ghost" color={(online === false && 'error') || 'success'}>
            {sentenceCase(online ? 'online' : 'offline')}
          </Label>
        </Box>
        <Grid container spacing={3}>
          {productType === 'TH_DIMMER' && (
            <Dimmer deviceId={deviceId} data={currentData} />
            // <>
            //   {currentData.channel0 && (
            //     <Grid item xs={12} sm={4} md={3}>
            //       <DevicePowerStatus
            //         measureName="Channel 1"
            //         subValue={`${currentData.channel0.intensity} %`}
            //         value={currentData.channel0.power ? 'ON' : 'OFF'}
            //       />
            //     </Grid>
            //   )}
            //   {currentData.channel1 && (
            //     <Grid item xs={12} sm={4} md={3}>
            //       <DevicePowerStatus
            //         measureName="Channel 2"
            //         subValue={`${currentData.channel1.intensity} %`}
            //         value={currentData.channel1.power ? 'ON' : 'OFF'}
            //       />
            //     </Grid>
            //   )}
            //   {currentData.channel2 && (
            //     <Grid item xs={12} sm={4} md={3}>
            //       <DevicePowerStatus
            //         measureName="Channel 3"
            //         subValue={`${currentData.channel2.intensity} %`}
            //         value={currentData.channel2.power ? 'ON' : 'OFF'}
            //       />
            //     </Grid>
            //   )}
            //   {currentData.channel3 && (
            //     <Grid item xs={12} sm={4} md={3}>
            //       <DevicePowerStatus
            //         measureName="Channel 4"
            //         subValue={`${currentData.channel3.intensity} %`}
            //         value={currentData.channel3.power ? 'ON' : 'OFF'}
            //       />
            //     </Grid>
            //   )}
            // </>
          )}
          {productType === 'TH_SWITCH' && (
            <Switch deviceId={deviceId} data={currentData} />
            // <>
            //   {currentData.channel0 && (
            //     <Grid item xs={12} sm={4} md={3}>
            //       <DevicePowerStatus
            //         measureName="Channel 1"
            //         subValue=""
            //         value={currentData.channel0.power ? 'ON' : 'OFF'}
            //       />
            //     </Grid>
            //   )}
            //   {currentData.channel1 && (
            //     <Grid item xs={12} sm={4} md={3}>
            //       <DevicePowerStatus
            //         measureName="Channel 2"
            //         subValue=""
            //         value={currentData.channel1.power ? 'ON' : 'OFF'}
            //       />
            //     </Grid>
            //   )}
            //   {currentData.channel2 && (
            //     <Grid item xs={12} sm={4} md={3}>
            //       <DevicePowerStatus
            //         measureName="Channel 3"
            //         subValue=""
            //         value={currentData.channel2.power ? 'ON' : 'OFF'}
            //       />
            //     </Grid>
            //   )}
            //   {currentData.channel3 && (
            //     <Grid item xs={12} sm={4} md={3}>
            //       <DevicePowerStatus
            //         measureName="Channel 4"
            //         subValue=""
            //         value={currentData.channel3.power ? 'ON' : 'OFF'}
            //       />
            //     </Grid>
            //   )}
            // </>
          )}
          {productType === 'TH_DOSER' && (
            <Doser deviceId={deviceId} data={currentData} />
            // <>
            //   <Grid item xs={12} sm={3} md={3}>
            //     <DeviceNutrientStatus measureName="TDS" unitName="ppm" value={currentData.tds} />
            //   </Grid>

            //   <Grid item xs={12} sm={3} md={3}>
            //     <DeviceNutrientStatus measureName="EC" unitName="μS/cm" value={currentData.ec} />
            //   </Grid>

            //   <Grid item xs={12} sm={3} md={3}>
            //     <DeviceNutrientStatus measureName="pH" unitName="pH" value={currentData.ph} />
            //   </Grid>

            //   <Grid item xs={12} sm={3} md={3}>
            //     <DeviceNutrientStatus
            //       measureName="Temperature"
            //       unitName="°C"
            //       value={currentData.temperature}
            //     />
            //   </Grid>

            //   <Grid item xs={12} md={12} lg={12}>
            //     <DeviceNutrientHistorical deviceId={deviceId} />
            //   </Grid>
            // </>
          )}
          {productType === 'TH_NUTRIENT_SENSOR' && (
            <NutrientSensor deviceId={deviceId} data={currentData} />
            // <>
            //   <Grid item xs={12} sm={3} md={3}>
            //     <DeviceNutrientStatus measureName="TDS" unitName="ppm" value={currentData.tds} />
            //   </Grid>

            //   <Grid item xs={12} sm={3} md={3}>
            //     <DeviceNutrientStatus measureName="EC" unitName="μS/cm" value={currentData.ec} />
            //   </Grid>

            //   <Grid item xs={12} sm={3} md={3}>
            //     <DeviceNutrientStatus measureName="pH" unitName="pH" value={currentData.ph} />
            //   </Grid>

            //   <Grid item xs={12} sm={3} md={3}>
            //     <DeviceNutrientStatus
            //       measureName="Temperature"
            //       unitName="°C"
            //       value={currentData.temperature}
            //     />
            //   </Grid>

            //   <Grid item xs={12} md={12} lg={12}>
            //     <DeviceNutrientHistorical deviceId={deviceId} showEvent={false} />
            //   </Grid>
            // </>
          )}
          {productType === 'TH_AMBIENT' && (
            <AmbientSensor deviceId={deviceId} data={currentData} />
            // <>
            //   <Grid item xs={12} sm={3} md={3}>
            //     <DeviceNutrientStatus
            //       measureName="Temperature"
            //       unitName="°C"
            //       value={currentData.temperature}
            //     />
            //   </Grid>

            //   <Grid item xs={12} sm={3} md={3}>
            //     <DeviceNutrientStatus
            //       measureName="Humidity"
            //       unitName="%RH"
            //       value={currentData.humidity}
            //     />
            //   </Grid>

            //   <Grid item xs={12} sm={3} md={3}>
            //     <DeviceNutrientStatus measureName="CO2" unitName="ppm" value={currentData.co2} />
            //   </Grid>

            //   <Grid item xs={12} sm={3} md={3}>
            //     <DeviceNutrientStatus
            //       measureName="Light Intensity"
            //       unitName="lux"
            //       value={currentData.intensity}
            //     />
            //   </Grid>

            //   <Grid item xs={12} md={12} lg={12}>
            //     <DeviceAmbientHistorical deviceId={deviceId} />
            //   </Grid>
            // </>
          )}
        </Grid>
      </Container>
    </Page>
  );
}

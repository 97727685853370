import { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import apiHandler from '../api/axios-api';

// ----------------------------------------------------------------------

const AccountData = () => {
  const token = localStorage.getItem('access_token');
  let decoded;
  const [avatar, setAvatar] = useState(null);

  const fetchAvatar = async () => {
    const response = await apiHandler({ url: `/profile/picture`, responseType: 'blob' });
    // localStorage.setItem('user_avatar', response.data);
    // setAvatar(null));
    setAvatar(URL.createObjectURL(response.data));
  };

  useEffect(() => {
    fetchAvatar();
  }, []);

  try {
    decoded = jwtDecode(token);
  } catch {
    decoded = {};
  }

  return {
    displayName: decoded.display_name || decoded.email,
    email: decoded.email,
    defaultAvatar: '/static/mock-images/avatars/avatar_default.jpg',
    avatar
  };
};

export default AccountData;

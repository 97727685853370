import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Device from './pages/Device';
import DeviceDashboard from './pages/DeviceDashboard';
import Group from './pages/Group';
import GroupDashboard from './pages/GroupDashboard';
import NotFound from './pages/Page404';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'groups', element: <Group /> },
        { path: 'groups/:groupId', element: <GroupDashboard /> },
        { path: 'devices', element: <Device /> },
        { path: 'devices/:deviceId', element: <DeviceDashboard /> }
      ]
    },
    {
      path: '/dashboard',
      element: <LogoOnlyLayout />,
      children: [
        // { path: '/', element: <Navigate to="/dashboard" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    // the following route should not happen due to nginx routing
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [{ path: '/', element: <Navigate to="/dashboard" /> }]
    },

    { path: '*', element: <Navigate to="/dashboard/404" replace /> }
  ]);
}

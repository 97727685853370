import PropTypes from 'prop-types';
// import React, { useState, useEffect } from 'react';

// material
import { Grid } from '@material-ui/core';
// components
import { DeviceNutrientStatus, DeviceAmbientHistorical } from '../_dashboard/devices';

AmbientSensor.propTypes = {
  deviceId: PropTypes.string,
  data: PropTypes.any.isRequired
};

export default function AmbientSensor({ deviceId, data }) {

  const _temperature = data.temperature ? Number(data.temperature) : null;
  const _humidity = data.humidity ? Number(data.humidity) : null;
  const _co2 = data.co2 ? Number(data.co2) : null;
  const _intensity = data.intensity ? Number(data.intensity) : null;

  return (
    <>
      <Grid item xs={12} sm={3} md={3}>
        <DeviceNutrientStatus
          measureName="Temperature"
          unitName="°C"
          value={_temperature}
          valueFixed={1}
        />
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <DeviceNutrientStatus measureName="Humidity" unitName="%RH" value={_humidity} valueFixed={1} />
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <DeviceNutrientStatus measureName="CO2" unitName="ppm" value={_co2} />
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <DeviceNutrientStatus
          measureName="Light Intensity"
          unitName="lux"
          value={_intensity}
        />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <DeviceAmbientHistorical deviceId={deviceId} />
      </Grid>
    </>
  );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/styles';
import {
  Divider,
  Box,
  Grid,
  Typography,
  Modal,
  Card,
  CardHeader,
  CardContent,
  Collapse
} from '@mui/material';

// material
import SettingsIcon from '@material-ui/icons/Settings';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { GetIcon } from '../../../Utils';
import { ModalTimePicker, InputNumber, BigChip } from '..';

import BackendService from '../../../../api/BackendService';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  p: 3,
  borderRadius: 8,
  boxShadow: 10,
};

const modalContentStyle = {
  overflowY: 'auto',
  maxHeight: '80vh'
};

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
};

const backendService = new BackendService();
DimmerSettings.propTypes = {
  deviceId: PropTypes.string.isRequired
};

export default function DimmerSettings({ deviceId }) {
  // const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    device: {},
    settings: []
  });

  const [expand, setExpand] = useState({
    0: true,
    1: false,
    2: false,
    3: false
  });

  const { device, settings } = state;

  const handleOpen = () => {
    fetchData();
    return setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return false;
    }
    return setOpen(false);
  };

  const handleExpand = (chIndex) => {
    expand[chIndex] = !expand[chIndex];
    setExpand({ [chIndex]: expand[chIndex] });
  };

  const fetchData = async () => {
    const device = await backendService.getDeviceInfo(deviceId);
    const settings = device ? device.settings : [];

    // Apply scheduleList to schedule
    Object.keys(settings).map((c) => {
      settings[c].schedule = settings[c].scheduleList || [];
      return settings[c];
    });

    // Apply remove intensity and scheduleList to schedule
    // Object.keys(settings).map((c) => {
    //   Object.keys(settings[c].scheduleList).map(
    //     (s) => delete settings[c].scheduleList[s].intensity
    //   );
    //   return settings[c].schedule = settings[c].scheduleList;
    // });

    setState({
      device,
      settings
    });
  };

  const getIconStatusColor = (isOnline) =>
    isOnline ? theme.palette.status.online : theme.palette.status.offline;

  const updateDeviceSettings = (params) => {
    backendService.updateDeviceSettings(deviceId, params);

    setState({
      ...state,
      settings
    });
  };

  const ensureScheduleItem = (schedule = {}) => {
    schedule.enable = typeof schedule.enable !== 'undefined' ? schedule.enable : true;
    schedule.time = parseInt(schedule.time || 80000, 10);
    schedule.duration = schedule.duration || 43200;

    return schedule;
  };

  const addScheduleItem = (chIndex, schedule = {}) => {
    settings[chIndex].schedule.push(ensureScheduleItem(schedule));
    updateDeviceSettings([settings[chIndex]]);
  };

  const removeScheduleItem = (chIndex, schIndex) => {
    settings[chIndex].schedule.splice(schIndex, 1);
    updateDeviceSettings([settings[chIndex]]);
  };

  const ScheduleItem = ({ chIndex, schIndex, schedule }) => {
    schedule = ensureScheduleItem(schedule);
    const _channel = settings[chIndex].channel.toString();

    /* renderTime for ModalTimePicker */
    const renderTime = new Date();
    renderTime.setHours((schedule.time / 10000) % 100);
    renderTime.setMinutes((schedule.time / 100) % 100);

    let durationTime = schedule.time;
    let durationHours = Math.floor(schedule.duration / 3600);
    let durationMinutes = Math.floor(schedule.duration / 60) % 60;

    const _updateDeviceSettings = () => {
      settings[chIndex].schedule[schIndex].time = durationTime;
      settings[chIndex].schedule[schIndex].duration =
        (durationHours * 3600) + (durationMinutes * 60);

      updateDeviceSettings([
        {
          channel: _channel,
          scheduleList: settings[chIndex].schedule
        }
      ]);
    };

    return (
      <Grid container mt={1} spacing={2} pl={3} alignItems="center">
        <Grid item xs={1} textAlign="left">
          <Typography id="modal-modal-title" variant="h5">
            #{schIndex + 1}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <ModalTimePicker
            value={renderTime}
            fontSize={40}
            ampm={false}
            justifyContent="center"
            onChange={(date) => {
              if (!date) {
                return;
              }
              durationTime = date.getHours() * 10000 + date.getMinutes() * 100;
            }}
            onAccept={() => _updateDeviceSettings()}
            cancelText=""
          />
        </Grid>
        <Grid item xs={2} textAlign="center">
          <Typography id="modal-modal-title" variant="h5">
            Duration
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <InputNumber
            defaultValue={durationHours}
            min={0}
            max={23}
            step={1}
            justifyContent="center"
            onChange={(value) => {
              durationHours = value;
              _updateDeviceSettings();
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <InputNumber
            defaultValue={durationMinutes}
            min={0}
            max={59}
            step={1}
            justifyContent="center"
            onChange={(value) => {
              durationMinutes = value;
              _updateDeviceSettings();
            }}
          />
        </Grid>
        <Grid item xs={1} textAlign="right">
          <GetIcon
            icon={<DeleteForeverIcon sx={{ fontSize: 40, color: theme.palette.red.main }} />}
            onClick={() => removeScheduleItem(chIndex, schIndex)}
          />
        </Grid>
      </Grid>
    );
  };
  ScheduleItem.propTypes = {
    chIndex: PropTypes.number.isRequired,
    schIndex: PropTypes.number.isRequired,
    schedule: PropTypes.object
  };

  // useEffect(() => {
  //   return () => {};
  // }, []);

  // console.log(device);

  return (
    <>
      <Box sx={buttonStyle}>
        <GetIcon icon={<SettingsIcon />} onClick={handleOpen} />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <Box sx={modalStyle}>
          <Grid container spacing={0} sx={{ pt: 1, pb: 2 }} alignItems="center">
            <Grid item xs={6}>
              <Typography id="modal-modal-title" variant="h4">
                {device ? device.deviceName : '...'} Settings
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <GetIcon icon={<CancelRoundedIcon sx={{ fontSize: 40 }} />} onClick={handleClose} />
            </Grid>
          </Grid>

          <Box sx={modalContentStyle}>
            {settings.map((_setting, chIndex) => {
              const _channel = _setting.channel.toString();
              const _power = _setting.power;
              const _schedule = _setting.schedule || [];

              settings[chIndex].channel = _channel;

              return (
                <Card key={chIndex} sx={{ mb: 2, mx: 3 }}>
                  <CardHeader
                    title={`Channel ${chIndex + 1}`}
                    sx={{ pb: 2, cursor: 'pointer' }}
                    action={
                      <>
                        <GetIcon icon={expand[chIndex] ? <ExpandLessIcon /> : <ExpandMoreIcon />} />
                      </>
                    }
                    onClick={() => handleExpand(chIndex)}
                  />
                  <Collapse in={expand[chIndex]} timeout="auto" unmountOnExit>
                    <Divider variant="middle" />
                    <CardHeader
                      title="Power Control"
                      action={
                        <>
                          <GetIcon
                            icon={
                              <PowerSettingsNewIcon
                                style={{ color: getIconStatusColor(_power), fontSize: 40 }}
                              />
                            }
                            onClick={() => {
                              settings[chIndex].power = !_power;
                              updateDeviceSettings([
                                {
                                  channel: _channel,
                                  power: settings[chIndex].power
                                }
                              ]);
                            }}
                          />
                        </>
                      }
                    />
                    <CardHeader
                      title=""
                      action={
                        <>
                          <GetIcon
                            icon={
                              <AddCircleIcon
                                style={{ fontSize: 40, color: theme.palette.green.main }}
                              />
                            }
                            onClick={() => {
                              addScheduleItem(chIndex);
                            }}
                          />
                        </>
                      }
                      sx={{ pb: 2 }}
                    />
                    {_schedule.length > 0 && (
                      <CardContent sx={{ mt: -9, pr: 2 }}>
                        <Grid container spacing={2} pl={3} alignItems="center">
                          <Grid item xs={1} />
                          <Grid item xs={2} textAlign="center">
                            <BigChip label="Action Time" color="default" />
                          </Grid>
                          <Grid item xs={2} textAlign="center" />
                          <Grid item xs={3} textAlign="center">
                            <BigChip label="Hours" color="default" />
                          </Grid>
                          <Grid item xs={3} textAlign="center">
                            <BigChip label="Minutes" color="default" />
                          </Grid>
                          <Grid item xs={1} />
                        </Grid>
                        {_schedule.map((schedule, schIndex) => (
                          <ScheduleItem
                            key={schIndex}
                            chIndex={chIndex}
                            schIndex={schIndex}
                            schedule={schedule}
                          />
                        ))}
                      </CardContent>
                    )}
                  </Collapse>
                </Card>
              );
            })}
          </Box>

          {/* {settings.map((_setting, chIndex) => {
            const __channel = _setting.channel.toString();
            const __power = _setting.power;
            const __schedule = _setting.schedule || {};

            __schedule.enable = __schedule.enable || false;
            __schedule.time = parseInt(__schedule.time || 800, 10);
            __schedule.duration = __schedule.duration || 43200;

            // renderTime for ModalTimePicker
            const renderTime = new Date();
            renderTime.setHours((__schedule.time / 100) % 100);
            renderTime.setMinutes(__schedule.time % 100);

            let durationHours = Math.floor(__schedule.duration / 3600);
            let durationMinutes = __schedule.duration % 60;

            return (
              <Card key={chIndex} sx={{ mb: 1 }}>
                <CardHeader title={`Channel ${chIndex + 1}`} sx={{ pb: 2 }} />
                <Divider variant="middle" />
                <CardHeader
                  title="Power Control"
                  action={
                    <>
                      <GetIcon
                        icon={
                          <PowerSettingsNewIcon
                            style={{ color: getIconStatusColor(__power), fontSize: 40 }}
                          />
                        }
                        onClick={() => {
                          settings[chIndex].power = !__power;

                          updateDeviceSettings([
                            {
                              channel: __channel,
                              power: settings[chIndex].power,
                              intensity: __power ? 100 : 0
                            }
                          ]);
                        }}
                      />
                    </>
                  }
                />
                <CardHeader
                  title="Schedule List"
                  action={
                    <>
                      <IOSSwitch
                        checked={__schedule.enable}
                        onChange={(e) => {
                          settings[chIndex].schedule.enable = e.target.checked;

                          updateDeviceSettings([
                            {
                              channel: __channel,
                              schedule: {
                                enable: settings[chIndex].schedule.enable
                              }
                            }
                          ]);
                        }}
                      />
                    </>
                  }
                />{' '}
                <CardContent sx={{ pt: 1 }}>
                  <Collapse in={__schedule.enable} timeout="auto" unmountOnExit>
                    <Grid container spacing={1} pl={3} alignItems="center">
                      <Grid item xs={3} />
                      <Grid item xs={3} textAlign="center">
                        <BigChip label="On Time" color="primary" />
                      </Grid>
                      <Grid item xs={3} textAlign="center">
                        <BigChip label="Hours" color="info" />
                      </Grid>
                      <Grid item xs={3} textAlign="center">
                        <BigChip label="Minutes" color="info" />
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} pl={3} alignItems="center">
                      <Grid item xs={3} textAlign="left">
                        <Typography id="modal-modal-title" variant="body1">
                          Duration
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <ModalTimePicker
                          value={renderTime}
                          fontSize={40}
                          ampm={false}
                          justifyContent="center"
                          onChange={(date) => {
                            if (!date) {
                              return;
                            }

                            settings[chIndex].schedule.time =
                              date.getHours() * 100 + date.getMinutes();

                            setState({
                              ...state,
                              settings
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <InputNumber
                          defaultValue={durationHours}
                          min={0}
                          max={23}
                          step={1}
                          onChange={(value) => {
                            durationHours = value;
                          }}
                          justifyContent="center"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <InputNumber
                          defaultValue={durationMinutes}
                          min={0}
                          max={59}
                          step={1}
                          onChange={(value) => {
                            durationMinutes = value;
                          }}
                          justifyContent="center"
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={0} alignItems="center" pt={2}>
                      <Grid item textAlign="right" xs={12}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            settings[chIndex].schedule.duration =
                              durationHours * 3600 + (3600 - (60 - durationMinutes));

                            updateDeviceSettings([
                              {
                                channel: __channel,
                                schedule: {
                                  time: settings[chIndex].schedule.time,
                                  duration: settings[chIndex].schedule.duration
                                }
                              }
                            ]);
                          }}
                        >
                          Apply
                        </Button>
                      </Grid>
                    </Grid>
                  </Collapse>
                </CardContent>
              </Card>
            );
          })} */}
        </Box>
      </Modal>
    </>
  );
}

import PropTypes from 'prop-types';
// import React, { useState, useEffect } from 'react';

// material
import { Grid } from '@material-ui/core';
// components
import { DeviceNutrientStatus, DeviceNutrientHistorical } from '../_dashboard/devices';

Doser.propTypes = {
  deviceId: PropTypes.string,
  data: PropTypes.any.isRequired
};

export default function Doser({ deviceId, data }) {

  const _temperature = data.temperature ? Number(data.temperature) : null;
  const _ec = data.ec ? Number.parseFloat(data.ec / 1000) : null;
  const _ph = data.ph ? Number(data.ph) : null;
  const _tds = data.tds ? Number(data.tds) : null;

  return (
    <>
      <Grid item xs={12} sm={3} md={3}>
        <DeviceNutrientStatus
          measureName="Temperature"
          unitName="°C"
          value={_temperature}
          valueFixed={1}
        />
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <DeviceNutrientStatus
          measureName="EC"
          unitName="mS/cm"
          value={_ec}
          valueFixed={1}
        />
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <DeviceNutrientStatus measureName="pH" unitName="pH" value={_ph} valueFixed={1} />
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <DeviceNutrientStatus measureName="TDS" unitName="ppm" value={_tds} valueFixed={0} />
      </Grid>

      <Grid item xs={12} md={12} lg={12}>
        <DeviceNutrientHistorical deviceId={deviceId} />
      </Grid>
    </>
  );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { useTheme } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Typography,
  Modal,
  Card,
  CardHeader,
  CardContent,
  Collapse
} from '@mui/material';

// material
import SettingsIcon from '@material-ui/icons/Settings';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { GetIcon } from '../../../Utils';
import { InputNumber, IOSSwitch, BigChip } from '..';

import BackendService from '../../../../api/BackendService';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  p: 3,
  borderRadius: 8,
  boxShadow: 10,
};

const buttonStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
};

const backendService = new BackendService();
DoserSettings.propTypes = {
  deviceId: PropTypes.string.isRequired
};
export default function DoserSettings({ deviceId }) {
  // const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    device: {},
    setpoints: {}
  });
  const { device, setpoints } = state;

  const handleOpen = () => {
    fetchData();
    return setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return false;
    }
    return setOpen(false);
  };

  const fetchData = async () => {
    const _device = await backendService.getDeviceInfo(deviceId);
    const _tmpSetpoints = _device.settings ? _device.settings.setpoints : [];
    const _newSetpoints = {};

    await Promise.all(
      _tmpSetpoints.map((sp) => {
        _newSetpoints[sp.parameter] = sp;
        return sp;
      })
    );

    setState({
      ...state,
      device: _device,
      setpoints: _newSetpoints
    });
  };

  const updateDeviceSettings = (params) => {
    backendService.updateDeviceSettings(deviceId, params);

    setState({
      ...state,
      setpoints
    });
  };

  const processSetpoint = (key, value, variant) => {
    variant = Number(parseFloat(variant).toFixed(2));

    switch (key) {
      case 'EC':
        setpoints[key].min = value;
        setpoints[key].max = value + variant;
        break;

      case 'PH':
        setpoints[key].min = value - variant;
        setpoints[key].max = value + variant;
        break;

      default:
        setpoints[key].min = value;
        setpoints[key].max = value;
        break;
    }

    return setpoints[key];
  };

  const getRenderData = (_setpoint) => {
    const result = {
      key: _setpoint.parameter,
      name: _setpoint.parameter,
      unit: '-'
    };

    switch (_setpoint.parameter) {
      case 'EC':
        result.name = 'EC';
        result.unit = 'mS/cm';
        result.inputMin = 0;
        result.inputMax = 6;
        result.value = _setpoint.min;
        result.variant = Number(parseFloat(_setpoint.max - _setpoint.min).toFixed(2));
        break;

      case 'PH':
        result.name = 'pH';
        result.unit = 'Unit';
        result.inputMin = 2;
        result.inputMax = 7.5;
        result.value = (_setpoint.max + _setpoint.min) / 2;
        result.variant = Number(parseFloat((_setpoint.max - _setpoint.min) / 2).toFixed(2));
        break;

      default:
        break;
    }

    return result;
  };

  // useEffect(() => {
  //   return () => {};
  // }, []);

  // console.log(state);
  return (
    <>
      {/* {getIcon(settings2Outline, handleOpen)} */}
      <Box sx={buttonStyle}>
        <GetIcon icon={<SettingsIcon />} onClick={handleOpen} />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableAutoFocus
      >
        <Box sx={modalStyle}>
          <Grid container spacing={0} sx={{ pt: 1, pb: 2 }} alignItems="center">
            <Grid item xs={6}>
              <Typography id="modal-modal-title" variant="h4">
                {device ? device.deviceName : '...'} Settings
              </Typography>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <GetIcon icon={<CancelRoundedIcon sx={{ fontSize: 40 }} />} onClick={handleClose} />
            </Grid>
          </Grid>

          {Object.keys(setpoints)
            .sort()
            .map((key, index) => {
              const _setpoint = setpoints[key];
              const renderObj = getRenderData(_setpoint);

              return (
                <Card key={index} sx={{ mb: 2, mx: 3 }}>
                  <CardHeader
                    title={`${renderObj.name} Controller`}
                    action={
                      <>
                        <IOSSwitch
                          checked={_setpoint.enable}
                          onChange={(e) => {
                            setpoints[key].enable = e.target.checked;
                            updateDeviceSettings({
                              setpoints: [setpoints[key]]
                            });
                          }}
                        />
                      </>
                    }
                  />
                  <CardContent sx={{ pt: 0 }}>
                    <Collapse in={_setpoint.enable} timeout="auto" unmountOnExit>
                      <Grid container spacing={2} pl={3} alignItems="center">
                        <Grid item xs={6} />
                        <Grid item xs={3} textAlign="center">
                          <BigChip label={`${renderObj.unit}`} color="default" />
                        </Grid>
                        <Grid item xs={3} textAlign="center">
                          <BigChip label="Variation" color="default" />
                        </Grid>
                      </Grid>
                      <Grid container mt={1} spacing={2} pl={3} alignItems="center">
                        <Grid item xs={6}>
                          <Typography id="modal-modal-title" variant="h5">
                            Target {renderObj.name}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <InputNumber
                            defaultValue={renderObj.value}
                            min={renderObj.inputMin}
                            max={renderObj.inputMax}
                            step={0.1}
                            decimalPlace={1}
                            isInteger={false}
                            onChange={(_value) => {
                              updateDeviceSettings({
                                setpoints: [
                                  processSetpoint(renderObj.key, _value, renderObj.variant)
                                ]
                              });
                            }}
                            justifyContent="center"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <InputNumber
                            defaultValue={renderObj.variant}
                            min={0}
                            max={3}
                            step={0.1}
                            decimalPlace={1}
                            isInteger={false}
                            onChange={(_value) => {
                              updateDeviceSettings({
                                setpoints: [processSetpoint(renderObj.key, renderObj.value, _value)]
                              });
                            }}
                            justifyContent="center"
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </CardContent>
                </Card>
              );
            })}
        </Box>
      </Modal>
    </>
  );
}
